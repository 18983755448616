import { v4 as uuidv4 } from 'uuid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hello 👋 I'm",
  name: 'Jack,',
  subtitle: '',
  cta: 'Learn More',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne:
    'Currently a software engineer at Mulesoft/Salesforce working on Runtime Fabric, a runtime platform leveraging the power of Kubernetes.',
  paragraphTwo: '',
  paragraphThree: ' ',
  resume:
    'https://cdn.discordapp.com/attachments/686423533688979539/750448915458949140/Screen_Shot_2020-09-01_at_4.15.59_PM.png', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv4(),
    img: 'aquatools.png',
    title: 'AquaHQ',
    info:
      'Third party (overlay) tool for NFT Marketplaces (such as OpenSea), providing users with rich features such as NFT rarity, collection monitoring, autobidding, and more.',
    info2: '1MM+ Total Trading Volume, 50MM+ NFTs ranked accurately using custom algorithm and efficient indexing',
    url: 'https://www.aquatools-nft.com/',
    repo: '',
    mocks:
      'https://www.figma.com/file/2bn1ZFnh2ISHZznHNuVysq/AquaTools-(Copy)?type=design&node-id=0-1&mode=design&t=dBuP4BUFuszFoIkx-0',
  },
  {
    id: uuidv4(),
    img: 'project_1.jpg',
    title: 'Light Development',
    info:
      'B2B structured service that provided whitelabeled Chrome extensions / Electron Desktop apps to help aid sneaker resellers through automation.',
    info2: '10,000+ DAU, 100+ Partnered Clients at Peak',
    url: 'https://discord.gg/P3usN2C5Gm',
    repo: '',
    mocks: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Would you like to get in touch?',
  btn: 'Contact Me',
  email: 'li.jiahong2000@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: uuidv4(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: uuidv4(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: uuidv4(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jack-li-b5131912b/',
    },
    {
      id: uuidv4(),
      name: 'github',
      url: 'https://github.com/jiahongli18',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
